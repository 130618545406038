<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <!-- <span class="a-fs-16 a-fw-700">收益账单详情</span> -->
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="optionsincomeDistri" placeholder="请选择（可输入搜索）" />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
                <le-date-range ref="dateRange" label="日期" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" />
            </le-search-form>
            <le-pagview ref="financeSYRZInfo" @setData="setTableData" :pagerCount="5" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financeSYRZInfoList">
                <el-table 
                    ref="financeSYRZInfoList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    @filter-change='filterFun' 
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="orderSn" label="订单编号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderSn?row.orderSn:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="transactionId" label="三方交易号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.transactionId?row.transactionId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="订单类型" 
                        min-width="100"
                        column-key="orderType"
                        :filter-multiple='false'
                        :filters="orderTypeDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="支付渠道" 
                        min-width="100"
                        column-key="payChannel"
                        :filter-multiple='false'
                        :filters="payChannelDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderPrice" label="订单金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payAmount" label="总分账金额" min-width="100">
                        <template slot-scope="{ row }">
                            <!-- <span>￥{{ util.numFormat(row.payAmount) }}</span> -->
                            <span>￥{{ util.numFormat(row.incomeTotal || 0) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="商户分账金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.incomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fee" label="手续费" min-width="100">
                        <template slot-scope="{ row }">
                            <span v-if="row.fee || row.fee === 0 || row.fee === '0'">￥{{ util.numFormat(row.fee || 0) }}</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="moneyType" 
                        label="分账金额类型" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.moneyType | initDic(moneyTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="订单状态" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderStatus | initDic(orderStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="支付状态" 
                        min-width="103"
                        column-key="payStatus"
                        :filter-multiple='false'
                        :filters="payStatusDic">
                        <template slot-scope="{ row }">
                            <span>{{ row.payStatus | initDic(payStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="入账状态" 
                        min-width="103"
                        column-key="incomeStatus"
                        :filter-multiple='false'
                        :filters="enterAccountStatusDic"
                        :filtered-value='incomeStatusDefault'>
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeStatus | initDic(enterAccountStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="入账类型" 
                        min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeType | initDic(incomeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="商户" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName?row.companyName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomeName" label="项目" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeName?row.incomeName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="站点" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName?row.stationName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="historyRefundAmount" label="历史入账退款" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.historyRefundAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="message" label="说明" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.message?row.message:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <div class="a-flex-rfsc" style="margin-right: 40px">
                            <span class="a-fs-12 a-c-normal">结束订单总金额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.orderPriceTotal)}}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-fs-12 a-c-normal">结束订单总收益：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{util.numFormat(scope.val.incomePriceTotal)}}</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.backOrderIncomePage,
                    method: "post",
                    params: {
                        companyId: this.$route.query.companyId,
                        endDate: this.$route.query.endDate,
                        incomeType: this.$route.query.incomeType,
                        incomeId: '',
                        startDate: this.$route.query.startDate,
                        stationId: '',
                        incomeStatus: this.$route.query.incomeStatus,
                        orderType: '',
                        payChannel: '',
                        payStatus: '',
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsStation: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",
                },//站点数据
                orderTypeDic: [],//订单类型
                payChannelDic: [],//支付渠道
                orderStatusDic: [],//订单状态
                payStatusDic: [],// 支付状态
                enterAccountStatusDic: [],//入账状态
                incomeTypeDic: [],//入账类型
                incomeStatusDefault: [],
                moneyTypeDic: [],//分账金额类型
            }
        },
        created () {
            this.$getDic('incomeOrderType').then(res=>{ this.orderTypeDic = res; })
            this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
            this.$getDic('orderStatus').then(res=>{ this.orderStatusDic = res; })
            this.$getDic('payStatus').then(res=>{ this.payStatusDic = res; })
            this.$getDic('incomeStatus').then(res=>{ this.enterAccountStatusDic = res; })
            this.$getDic('incomeType').then(res=>{ this.incomeTypeDic = res; })
            this.$getDic('moneyType').then(res=>{ this.moneyTypeDic = res; })
            this.$set(this.incomeStatusDefault,0,this.$route.query.incomeStatus)

        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //
            setTableData(data,) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    companyId: this.$route.query.companyId,
                    incomeType: this.$route.query.incomeType,
                    endDate: this.$route.query.endDate,
                    incomeId: '',
                    startDate: this.$route.query.startDate,
                    stationId: '',
                    incomeStatus: this.$route.query.incomeStatus,
                    orderType: '',
                    payChannel: '',
                    payStatus: '',
                };
                this.$refs['dateRange'].value1 = [this.$route.query.startDate,this.$route.query.endDate]
                this.$refs['financeSYRZInfoList'].clearFilter(['payStatus','payChannel','orderType'])
                this.$nextTick(()=>{
                    this.$set(this.incomeStatusDefault,0,this.$route.query.incomeStatus)
                })
                this.handlerSearch()
            },
            handlerSearch() {
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startDate,this.pageParam.params.endDate).length > 31){
                    this.$message.error('最大查询范围31天')
                    return
                }
                this.$refs['financeSYRZInfo'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='incomeStatus'){
                        if(value[key].length==0){
                            this.pageParam.params.incomeStatus = ''
                        }else{
                            this.pageParam.params.incomeStatus = value[key][0]
                        }
                    }
                    if(key=='orderType'){
                        if(value[key].length==0){
                            this.pageParam.params.orderType = ''
                        }else{
                            this.pageParam.params.orderType = value[key][0]
                        }
                    }
                    if(key=='payChannel'){
                        if(value[key].length==0){
                            this.pageParam.params.payChannel = ''
                        }else{
                            this.pageParam.params.payChannel = value[key][0]
                        }
                    }
                    if(key=='payStatus'){
                        if(value[key].length==0){
                            this.pageParam.params.payStatus = ''
                        }else{
                            this.pageParam.params.payStatus = value[key][0]
                        }
                    }
                }
                this.$refs['financeSYRZInfo'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 7,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 收益入账详情表',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>